import React, {FC, useCallback, useEffect, useState} from 'react';
import * as styles from './nav-bar.module.scss';
import {Button, IconButton} from '@mui/material';
import LimitedWidthLayout from '../../limited-width-layout/limited-width-layout';
import logo from '../../../images/svg/Logo.svg';
import phone from '../../../images/svg/tel.svg';
import location from '../../../images/svg/map.svg';
import {useDeviceContext} from '../../../shared/context/device.context';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CartIcon from "../../cart/cart-icon/cart-icon";
import {Link, navigate} from "gatsby";
import {minDeliveryProductPrice} from "../../../shared/data/data.utils";

interface NavBarProps {
    fixed?: boolean;
    limitedWidthSize?: number;
}

const NavBar: FC<NavBarProps> = ({fixed, limitedWidthSize}) => {
    const {isMobile} = useDeviceContext();
    const [sideBarActive, setSideBarActive] = useState(false);

    useEffect(() => {
        if (!isMobile) {
            setSideBarActive(false);
        }
    }, [isMobile]);

    const navigateAndCloseSideBar = useCallback((url: string) => {
        setSideBarActive(false);
        navigate(url);
    }, [setSideBarActive])


    return (
        <div
            className={`${styles.NavBar} ${fixed ? styles.fixed : ''}`}>
                <div className={styles.mainContainer}>
                    {/*<div className={styles.mobileBgHelper}>*/}
                    {/*</div>*/}
                    <div className={styles.headerTopContainer}>
                        <LimitedWidthLayout limitedWidthSize={limitedWidthSize || 1920}>
                            <div className={styles.headerTop}>
                        <div className={styles.headerLocation}>
                            <img src={location} alt="Location"/>
                            <a>Bochenska 5, Kraków </a>
                        </div>

                        <div>
                            Minimalna kwota zamówienia na dostawę — {minDeliveryProductPrice} zł
                        </div>

                        <div>
                            Godziny otwarcia: niedz. od 13:00 do 21:00; śr., czw., pt., sob.
                            od 13:00 do 22:00
                        </div>

                        <div className={styles.phone}>
                            <img src={phone} alt="Telephone"/>
                            <a href="tel:+48 883 341 617">+48 883 341 617</a>
                        </div>
                            </div>
                        </LimitedWidthLayout>
                    </div>
                    <LimitedWidthLayout limitedWidthSize={limitedWidthSize || 1920}>
                    <div className={styles.headerBottom}>
                            <IconButton className={styles.menuIcon}
                                        onClick={() => setSideBarActive(!sideBarActive)}><MenuRoundedIcon
                                fontSize='inherit'></MenuRoundedIcon></IconButton>

                        <Link to='/' className={styles.logo}>
                            <img className={styles.logoImage} src={logo} alt=''/>
                        </Link>

                        <div className={styles.navContainer}>
                            <Button variant='text' className={styles.navBtn} type="button"
                                    onClick={() => navigate('/')}>Menu</Button>
                            <Button variant='text' className={styles.navBtn} type="button"
                                    onClick={() => navigate('/restauracja')}>W Restauracji</Button>
                            <Button variant='text' className={styles.navBtn} type="button"
                                    onClick={() => navigate('/menu/wege')}>Wege</Button>
                            <Button variant='text' className={styles.navBtn} type="button"
                                    onClick={() => navigate('/dostawa')}>Dostawa</Button>
                            <Button variant='text' className={styles.navBtn} type="button"
                                    onClick={() => navigate('/o_nas')}>O nas</Button>
                            <Button variant='text' className={styles.navBtn} type="button"
                                    onClick={() => navigate('/kontakt')}>Kontakt</Button>
                        </div>

                        <CartIcon/>
                    </div>
                    </LimitedWidthLayout>
                    <div className={`${styles.sidebarContainer} ${sideBarActive ? styles.active : ''}`}>
                        <div className={`${styles.sidebar}`}>
                            <div className={styles.sidebarItems}>
                                <Button variant='text' className={styles.navBtn} type="button"
                                        onClick={() => navigateAndCloseSideBar('/')}>Menu</Button>
                                <Button variant='text' className={styles.navBtn} type="button"
                                        onClick={() => navigateAndCloseSideBar('/restauracja')}>W Restauracji</Button>
                                <Button variant='text' className={styles.navBtn} type="button"
                                        onClick={() => navigateAndCloseSideBar('/menu/wege')}>Wege</Button>
                                <Button variant='text' className={styles.navBtn} type="button"
                                        onClick={() => navigateAndCloseSideBar('/dostawa')}>Dostawa</Button>
                                <Button variant='text' className={styles.navBtn} type="button"
                                        onClick={() => navigateAndCloseSideBar('/o_nas')}>O nas</Button>
                                <Button variant='text' className={styles.navBtn} type="button"
                                        onClick={() => navigateAndCloseSideBar('/kontakt')}>Kontakt</Button>
                            </div>
                            <div className={styles.sidebarTransparentBg} onClick={() => setSideBarActive(false)}></div>

                        </div>
                    </div>
                </div>
        </div>
    );
};

export default NavBar;
